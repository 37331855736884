import axios from 'axios';

export const getQueryString = params =>
	Object.keys(params)
		.map(key => `${key}=${encodeURIComponent(params[key])}`)
		.join('&');

export const http = ({ method = 'GET', url = '', payload = null, options = {}, token = false, service = 'auth' }) => {
	// const servicesBaseUrl = {
	// 	auth: process.env.REACT_APP_API_AUTH_URL,
	// 	admin: process.env.REACT_APP_API_URL
	// };

	if (token) {
		const accessToken = localStorage.getItem('jwt_access_token');
		options.headers = { ...options.headers };
		options.headers.Authorization = `Bearer ${accessToken}`;
	}

	const requestURL = process.env.REACT_APP_API_URL + url;
	const requestObject = {
		method,
		url: requestURL,
		data: payload,
		...options
	};

	return new Promise((resolve, reject) => {
		axios(requestObject)
			.then(res => {
				resolve(res);
			})
			.catch(error => {
				reject(error);
			});
	});
};
