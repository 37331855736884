import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	searchTerm: '',
	page: 0,
	rowsPerPage: 10,
	orderBy: 'createdAt',
	order: 'desc',
	user: {
		response: {},
		loading: false,
		error: ''
	}
};

const userSlice = createSlice({
	name: 'user/early-signup',
	initialState,
	reducers: {
		setSearchTerm: (state, { payload }) => {
			state.searchTerm = payload;
		},
		setPage: (state, { payload }) => {
			state.page = payload;
		},
		setRowsPerPage: (state, { payload }) => {
			state.rowsPerPage = payload;
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload;
		},
		setOrder: (state, { payload }) => {
			state.order = payload;
		},
		getRegisteredUserBegin: state => {
			state.user.loading = true;
		},
		getRegisteredUserSuccess: (state, { payload }) => {
			state.user.loading = false;
			state.user.response = payload;
		},
		getRegisteredUserFailure: (state, { payload }) => {
			state.user.loading = false;
			state.user.error = payload;
		},
		resetState: () => ({
			...initialState
		})
	}
});

export const {
	setSearchTerm,
	setPage,
	setRowsPerPage,
	setOrderBy,
	setOrder,
	getRegisteredUserBegin,
	getRegisteredUserSuccess,
	getRegisteredUserFailure,
	resetState
} = userSlice.actions;

export const getRegisteredUser = createAsyncThunk('users/registered-users', async (params, { dispatch }) => {
	dispatch(getRegisteredUserBegin());
	try {
		const { data } = await http({ url: '/users', options: { params }, token: true, service: 'cms' });
		dispatch(getRegisteredUserSuccess(data));
	} catch (error) {
		dispatch(getRegisteredUserFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export default userSlice.reducer;
