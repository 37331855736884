import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import { setUserData } from './userSlice';

export const submitLogin = (payload, successCB) => async dispatch => {
	dispatch(loginBegin());
	return jwtService
		.signInWithEmailAndPassword(payload)
		.then(user => {
			dispatch(setUserData(user));
			successCB();
			return dispatch(loginSuccess());
		})
		.catch(error => {
			dispatch(showMessage({ message: error?.response?.data?.message }));
			return dispatch(loginError(error?.response?.data?.message));
		});
};

export const submitLoginWithFireBase =
	({ username, password }) =>
	async dispatch => {
		dispatch(loginBegin());
		if (!firebaseService.auth) {
			console.warn("Firebase Service didn't initialize, check your configuration");

			return () => false;
		}
		return firebaseService.auth
			.signInWithEmailAndPassword(username, password)
			.then(() => {
				return dispatch(loginSuccess());
			})
			.catch(error => {
				const usernameErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled'
				];
				const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];

				const response = {
					username: usernameErrorCodes.includes(error.code) ? error.message : null,
					password: passwordErrorCodes.includes(error.code) ? error.message : null
				};

				if (error.code === 'auth/invalid-api-key') {
					dispatch(showMessage({ message: error.message }));
				}

				return dispatch(loginError(response));
			});
	};

const initialState = {
	success: false,
	loading: false,
	error: {
		username: null,
		password: null
	}
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginBegin: (state, action) => {
			state.loading = true;
		},
		loginSuccess: (state, action) => {
			state.loading = false;
			state.success = true;
		},
		loginError: (state, action) => {
			state.loading = false;
			state.success = false;
			state.error = action.payload;
		}
	},
	extraReducers: {}
});

export const { loginSuccess, loginError, loginBegin } = loginSlice.actions;

export default loginSlice.reducer;
