import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	forgotPassword: { loading: false, response: {}, error: '' },
	resetPassword: { loading: false, response: {}, error: '' }
};

const passwordSlice = createSlice({
	name: 'auth/password',
	initialState,
	reducers: {
		forgotPasswordBegin: state => {
			state.forgotPassword.loading = true;
		},
		forgotPasswordSuccess: (state, { payload }) => {
			state.forgotPassword.loading = false;
			state.forgotPassword.response = payload;
		},
		forgotPasswordFailure: (state, { payload }) => {
			state.forgotPassword.loading = false;
			state.forgotPassword.error = payload;
		},
		resetPasswordBegin: state => {
			state.resetPassword.loading = true;
		},
		resetPasswordSuccess: (state, { payload }) => {
			state.resetPassword.loading = false;
			state.resetPassword.response = payload;
		},
		resetPasswordFailure: (state, { payload }) => {
			state.resetPassword.loading = false;
			state.resetPassword.error = payload;
		}
	},
	extraReducers: {}
});

export const {
	forgotPasswordBegin,
	forgotPasswordSuccess,
	forgotPasswordFailure,
	resetPasswordBegin,
	resetPasswordSuccess,
	resetPasswordFailure
} = passwordSlice.actions;

export const sendPasswordResetLink = createAsyncThunk(
	'password/forgot',
	async ({ payload, successCB }, { dispatch }) => {
		dispatch(forgotPasswordBegin());
		try {
			const response = await http({
				url: '/auth/admin/forgotPassword',
				method: 'POST',
				payload,
				service: 'auth'
			});
			dispatch(forgotPasswordSuccess(response?.data));
			dispatch(showMessage({ message: response?.data?.message, variant: 'success' }));
			successCB();
		} catch (error) {
			dispatch(forgotPasswordFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);
export const resetAdminPassword = createAsyncThunk('password/reset', async ({ payload, successCB }, { dispatch }) => {
	dispatch(resetPasswordBegin());
	try {
		const response = await http({
			url: '/auth/admin/resetPassword',
			method: 'POST',
			payload,
			service: 'auth'
		});
		dispatch(resetPasswordSuccess(response?.data));
		dispatch(showMessage({ message: response?.data?.message, variant: 'success' }));
		successCB();
	} catch (error) {
		dispatch(resetPasswordFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export default passwordSlice.reducer;
