import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http, downloadExcelFile } from 'lib';
import { showMessage } from 'app/store/fuse/messageSlice';

const initialState = {
	exportToExcel: {
		loading: false,
		error: ''
	}
};

const commonSlice = createSlice({
	name: 'common Slice',
	initialState,
	reducers: {
		exportToExcelBegin: state => {
			state.exportToExcel.loading = true;
		},
		exportToExcelSuccess: state => {
			state.exportToExcel.loading = false;
		},
		exportToExcelFailure: (state, { payload }) => {
			state.exportToExcel.loading = false;
			state.exportToExcel.error = payload;
		}
	}
});

export const { exportToExcelBegin, exportToExcelSuccess, exportToExcelFailure } = commonSlice.actions;

export const exportToExcel = createAsyncThunk(
	'export-to-excel',
	async ({ url, name, service = 'cms' }, { dispatch }) => {
		dispatch(exportToExcelBegin());
		try {
			const { data } = await http({
				url,
				options: { responseType: 'arraybuffer', headers: { 'Content-Type': 'blob' } },
				token: true,
				service
			});
			downloadExcelFile(data, name);
			dispatch(exportToExcelSuccess());
		} catch (error) {
			dispatch(exportToExcelFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export default commonSlice.reducer;
