import { authRoles } from 'app/auth';
import React from 'react';

const ForgotPasswordConfig = {
	settings: {
		layout: {
			config: { navbar: false, header: false, toolbar: false, footer: false }
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/forgot-password',
			component: React.lazy(() => import('./ForgotPassword'))
		}
	]
};

export default ForgotPasswordConfig;
