import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	searchTerm: '',
	page: 0,
	rowsPerPage: 10,
	orderBy: 'createdAt',
	order: 'desc',
	categoriesList: {
		response: [],
		loading: false,
		error: ''
	},
	category: { loading: false, response: {}, error: '' },
	deleteCategory: { loading: false, response: {}, error: '' },
	selectedCategory: {}
};

const categoriesSlice = createSlice({
	name: 'categories',
	initialState,
	reducers: {
		setSearchTerm: (state, { payload }) => {
			state.searchTerm = payload;
		},
		setPage: (state, { payload }) => {
			state.page = payload;
		},
		setRowsPerPage: (state, { payload }) => {
			state.rowsPerPage = payload;
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload;
		},
		setOrder: (state, { payload }) => {
			state.order = payload;
		},
		getCategoriesListBegin: state => {
			state.categoriesList.loading = true;
		},
		getCategoriesListSuccess: (state, { payload }) => {
			state.categoriesList.loading = false;
			state.categoriesList.response = payload;
		},
		getCategoriesListFailure: (state, { payload }) => {
			state.categoriesList.loading = false;
			state.categoriesList.error = payload;
		},
		setSelectedCategory: (state, { payload }) => {
			state.selectedCategory = payload;
		},

		deleteCategoryBegin: state => {
			state.deleteCategory.loading = true;
		},
		deleteCategorySuccess: (state, { payload }) => {
			state.deleteCategory.loading = false;
			state.deleteCategory.response = payload;
		},
		deleteCategoryError: (state, { payload }) => {
			state.deleteCategory.loading = false;
			state.deleteCategory.error = payload;
		},

		categoryActionBegin: state => {
			state.category.loading = true;
		},
		categoryActionSuccess: (state, { payload }) => {
			state.category.loading = false;
			state.category.response = payload;
		},
		categoryActionFailure: (state, { payload }) => {
			state.category.loading = false;
			state.category.error = payload;
		},
		resetState: () => ({
			...initialState
		})
	}
});

export const {
	resetState,
	getCategoriesListBegin,
	getCategoriesListSuccess,
	getCategoriesListFailure,
	setSearchTerm,
	setPage,
	setRowsPerPage,
	setOrderBy,
	setOrder,
	setSelectedCategory,
	deleteCategoryBegin,
	deleteCategoryError,
	deleteCategorySuccess,
	categoryActionBegin,
	categoryActionSuccess,
	categoryActionFailure
} = categoriesSlice.actions;

export const getCategoriesList = createAsyncThunk('categories/list', async (params, { dispatch }) => {
	dispatch(getCategoriesListBegin());

	let url = '/admin/categories';
	Object.keys(params).forEach((key, index) => {
		const seprater = index === 0 ? '?' : '&';
		url += `${seprater}${key}=${params[key]}`;
	});

	try {
		const { data } = await http({ url, token: true, service: 'auth' });
		dispatch(getCategoriesListSuccess(data?.data));
	} catch (error) {
		dispatch(getCategoriesListFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const deleteCategory = createAsyncThunk(
	'categories/list/delete/deleteCategory',
	async ({ url, successCB }, { dispatch }) => {
		dispatch(deleteCategoryBegin());
		try {
			const { data } = await http({ url, method: 'Delete', token: true, service: 'auth' });
			dispatch(deleteCategorySuccess(data?.data));
			dispatch(closeDialog());
			dispatch(
				showMessage({
					message: 'category Deleted!',
					variant: 'success'
				})
			);
			successCB();
			dispatch(setSelectedCategory({}));
		} catch (error) {
			dispatch(deleteCategoryError(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export const categoryAction = createAsyncThunk(
	'category/action',
	async ({ url, method, payload, successCB = () => {}, message }, { dispatch }) => {
		dispatch(categoryActionBegin());
		try {
			const { data } = await http({ url, token: true, payload, method, service: 'auth' });
			dispatch(categoryActionSuccess(data?.data));
			dispatch(closeDialog());
			dispatch(
				showMessage({
					message,
					variant: 'success'
				})
			);
			successCB(data?.data);
		} catch (error) {
			dispatch(categoryActionFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export default categoriesSlice.reducer;
