import React from 'react';
import FuseUtils from '@fuse/utils';
import Error404PageConfig from 'app/main/404/Error404PageConfig';
import ActivityLogConfig from 'app/main/ActivityLog/ActivityLogConfig';
import DashboardConfig from 'app/main/dashboard/DsahboardConfig';
import EmailCmsConfig from 'app/main/EmailCms/EmailCmsConfig';
import ForgotPasswordConfig from 'app/main/forgot-password/ForgotPasswordConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import ManageFaqsConfig from 'app/main/ManageFaqs/ManageFaqsConfig';
import ResetPasswordConfig from 'app/main/reset-password/ResetPasswordConfig';
import UsersConfig from 'app/main/users/UsersConfig';
import { Redirect } from 'react-router-dom';
import CategoriesConfig from 'app/main/Categories/CategoriesConfig';

const routeConfigs = [
	LoginConfig,
	ForgotPasswordConfig,
	ResetPasswordConfig,
	DashboardConfig,

	UsersConfig,
	CategoriesConfig,
	ManageFaqsConfig,
	EmailCmsConfig,
	ActivityLogConfig,
	Error404PageConfig
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/dashboard" />
	},
	{
		path: '*',
		component: () => <Redirect to="/404" />
	}
];

export default routes;
