import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles(theme => ({
	arrow: {
		// color: theme.palette.common.black
	},
	tooltip: {
		// backgroundColor: theme.palette.common.black,
		fontSize: 11
	}
}));

function CustomTooltip(props) {
	const classes = useStylesBootstrap();
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<Tooltip
			open={showTooltip}
			onOpen={() => setShowTooltip(true)}
			onClose={() => setShowTooltip(false)}
			arrow
			classes={classes}
			{...props}
		/>
	);
}

export default function CustomizedTooltips({ title = '', children }) {
	return <CustomTooltip title={title}>{children}</CustomTooltip>;
}
