import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	searchTerm: '',
	page: 0,
	rowsPerPage: 10,
	orderBy: 'createdAt',
	order: 'desc',
	selectedUserType: 'Resident',
	usersList: {
		response: [],
		loading: false,
		error: ''
	},
	deleteUser: { loading: false, response: {}, error: '' },
	selectedUser: {},
	toggleStatus: { loading: false, response: {}, error: '' }
};

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setSearchTerm: (state, { payload }) => {
			state.searchTerm = payload;
		},
		setPage: (state, { payload }) => {
			state.page = payload;
		},
		setRowsPerPage: (state, { payload }) => {
			state.rowsPerPage = payload;
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload;
		},
		setOrder: (state, { payload }) => {
			state.order = payload;
		},
		setSelectedUserType: (state, { payload }) => {
			state.selectedUserType = payload;
		},
		getUsersListBegin: state => {
			state.usersList.loading = true;
		},
		getUsersListSuccess: (state, { payload }) => {
			state.usersList.loading = false;
			state.usersList.response = payload;
		},
		getUsersListFailure: (state, { payload }) => {
			state.usersList.loading = false;
			state.usersList.error = payload;
		},
		setSelectedUser: (state, { payload }) => {
			state.selectedUser = payload;
		},

		deleteUserBegin: state => {
			state.deleteUser.loading = true;
		},
		deleteUserSuccess: (state, { payload }) => {
			state.deleteUser.loading = false;
			state.deleteUser.response = payload;
		},
		deleteUserError: (state, { payload }) => {
			state.deleteUser.loading = false;
			state.deleteUser.error = payload;
		},

		toggleStatusBegin: state => {
			state.toggleStatus.loading = true;
		},
		toggleStatusSuccess: (state, { payload }) => {
			state.toggleStatus.loading = false;
			state.toggleStatus.response = payload;
		},
		toggleStatusError: (state, { payload }) => {
			state.toggleStatus.loading = false;
			state.toggleStatus.error = payload;
		},

		resetState: () => ({
			...initialState
		})
	}
});

export const {
	setSelectedUserType,
	getUsersListBegin,
	getUsersListSuccess,
	getUsersListFailure,
	setSearchTerm,
	setPage,
	setRowsPerPage,
	setOrderBy,
	setOrder,
	setSelectedUser,
	deleteUserBegin,
	deleteUserError,
	deleteUserSuccess,
	resetState,
	toggleStatusBegin,
	toggleStatusError,
	toggleStatusSuccess
} = usersSlice.actions;

export const getUsersList = createAsyncThunk('users/list', async (params, { dispatch }) => {
	dispatch(getUsersListBegin());

	let url = '/admin/get/users';
	Object.keys(params).forEach((key, index) => {
		const seprater = index === 0 ? '?' : '&';
		url += `${seprater}${key}=${params[key]}`;
	});

	try {
		const { data } = await http({ url, token: true, service: 'auth' });
		dispatch(getUsersListSuccess(data?.data));
	} catch (error) {
		dispatch(getUsersListFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const deleteUser = createAsyncThunk('users/list/delete/user', async ({ url, successCB }, { dispatch }) => {
	dispatch(deleteUserBegin());
	try {
		const { data } = await http({ url, token: true, service: 'auth' });
		dispatch(deleteUserSuccess(data?.data));
		dispatch(closeDialog());
		dispatch(
			showMessage({
				message: 'User Deleted!',
				variant: 'success'
			})
		);
		successCB();
		dispatch(setSelectedUser({}));
	} catch (error) {
		dispatch(deleteUserError(error?.response?.data?.message));
		dispatch(closeDialog());
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const toggleStatus = createAsyncThunk(
	'users/list/toggle/user',
	async ({ url, method = 'get', payload, successCB, message = 'Status Updated' }, { dispatch }) => {
		dispatch(toggleStatusBegin());
		try {
			const { data } = await http({ url, method, payload, token: true, service: 'auth' });
			dispatch(toggleStatusSuccess(data?.data));
			dispatch(
				showMessage({
					message,
					variant: 'success'
				})
			);
			successCB();
		} catch (error) {
			dispatch(toggleStatusError(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export default usersSlice.reducer;
