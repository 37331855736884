import { authRoles } from 'app/auth';
import React from 'react';

const LoginConfig = {
	settings: {
		layout: {
			config: { navbar: false, header: false, toolbar: false, footer: false }
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/login',
			component: React.lazy(() => import('./Login'))
		}
	]
};

export default LoginConfig;
