import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { setDataToEdit } from 'app/main/documents/documentsSlice';

function FuseDialog(props) {
	const dispatch = useDispatch();
	const state = useSelector(({ fuse }) => fuse.dialog.state);
	const options = useSelector(({ fuse }) => fuse.dialog.options);

	return (
		<Dialog
			open={state}
			onClose={ev => {
				dispatch(closeDialog());
				dispatch(setDataToEdit({}));
			}}
			aria-labelledby="fuse-dialog-title"
			classes={{
				paper: 'rounded-8'
			}}
			{...options}
		/>
	);
}

export default FuseDialog;
