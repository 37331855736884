export { http, getQueryString } from './http';
export { getDate, getTime } from './getDateAndTime';
export { downloadExcelFile, downloadFile } from './downloadFile';

export function cleanObject(obj) {
	Object.keys(obj).forEach(key => {
		if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
			delete obj[key];
		}
	});
	return obj;
}
