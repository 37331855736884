import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	getProjects: { projects: [], loading: false, error: '' },
	getStatistics: { statistics: [], loading: false, error: '' },
	getCategoryViseProjects: { categoryViseProjects: [], loading: false, error: '' },
	getOrganizations: { organizations: [], loading: false, error: '' },
	organization: '',
	getSurveyResponse: { surveyResponse: [], loading: false, error: '' }
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		getProjectsListBegin: state => {
			state.getProjects.loading = true;
		},
		getProjectsListSuccess: (state, { payload }) => {
			state.getProjects.loading = false;
			state.getProjects.projects = payload;
		},
		getProjectsListFailure: (state, { payload }) => {
			state.getProjects.loading = false;
			state.getProjects.error = payload;
		},
		getStatisticsBegin: state => {
			state.getStatistics.loading = true;
		},
		getStatisticsSuccess: (state, { payload }) => {
			state.getStatistics.loading = false;
			state.getStatistics.statistics = payload;
		},
		getStatisticsFailure: (state, { payload }) => {
			state.getStatistics.loading = false;
			state.getStatistics.error = payload;
		},
		getOrganizationsBegin: state => {
			state.getOrganizations.loading = true;
		},
		getOrganizationsSuccess: (state, { payload }) => {
			state.getOrganizations.loading = false;
			state.getOrganizations.organizations = payload;
		},
		getOrganizationsFailure: (state, { payload }) => {
			state.getOrganizations.loading = false;
			state.getOrganizations.error = payload;
		},
		getSurveyResponseBegin: state => {
			state.getSurveyResponse.loading = true;
		},
		getSurveyResponseSuccess: (state, { payload }) => {
			state.getSurveyResponse.loading = false;
			state.getSurveyResponse.surveyResponse = payload;
		},
		getSurveyResponseFailure: (state, { payload }) => {
			state.getSurveyResponse.loading = false;
			state.getSurveyResponse.error = payload;
		},
		setOrganization: (state, { payload }) => {
			state.organization = payload;
		},
		getCategoryViseProjectsBegin: state => {
			state.getCategoryViseProjects.loading = true;
		},
		getCategoryViseProjectsSuccess: (state, { payload }) => {
			state.getCategoryViseProjects.loading = false;
			state.getCategoryViseProjects.categoryViseProjects = payload;
		},
		getCategoryViseProjectsFailure: (state, { payload }) => {
			state.getCategoryViseProjects.loading = false;
			state.getCategoryViseProjects.error = payload;
		}
	}
});

export const {
	getProjectsListBegin,
	getProjectsListSuccess,
	getProjectsListFailure,
	getStatisticsBegin,
	getStatisticsSuccess,
	getStatisticsFailure,
	getOrganizationsBegin,
	getOrganizationsSuccess,
	getOrganizationsFailure,
	getSurveyResponseBegin,
	getSurveyResponseSuccess,
	getSurveyResponseFailure,
	setOrganization,
	getCategoryViseProjectsBegin,
	getCategoryViseProjectsSuccess,
	getCategoryViseProjectsFailure
} = dashboardSlice.actions;

export const getProjectsList = createAsyncThunk('dashboard/projects', async (params, { dispatch }) => {
	dispatch(getProjectsListBegin());

	let url = '/admin/project';
	Object.keys(params).forEach((key, index) => {
		const seprater = index === 0 ? '?' : '&';
		url += `${seprater}${key}=${params[key]}`;
	});

	try {
		const { data } = await http({ url, token: true });
		dispatch(getProjectsListSuccess(data?.data));
	} catch (error) {
		dispatch(getProjectsListFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const getStatistics = createAsyncThunk('dashboard/statistics', async (params, { dispatch }) => {
	dispatch(getStatisticsBegin());

	let url = '/admin/dashboard/analytics';
	Object.keys(params).forEach((key, index) => {
		const seprater = index === 0 ? '?' : '&';
		url += `${seprater}${key}=${params[key]}`;
	});

	try {
		const { data } = await http({ url, token: true });
		dispatch(getStatisticsSuccess(data?.data));
	} catch (error) {
		dispatch(getStatisticsFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const getOrganizations = createAsyncThunk('dashboard/organization', async (params, { dispatch }) => {
	dispatch(getOrganizationsBegin());

	const url = '/admin/organization/list';
	try {
		const { data } = await http({ url, token: true });
		dispatch(getOrganizationsSuccess(data?.data));
		dispatch(setOrganization(data?.data?.[0]?._id));
	} catch (error) {
		dispatch(getOrganizationsFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});
export const getSurveyResponse = createAsyncThunk('dashboard/surveyResponse', async (params, { dispatch }) => {
	dispatch(getSurveyResponseBegin());
	let url = '/admin/project/survey';

	Object.keys(params).forEach((key, index) => {
		const seprater = index === 0 ? '?' : '&';
		url += `${seprater}${key}=${params[key]}`;
	});

	try {
		const { data } = await http({ url, token: true });
		dispatch(getSurveyResponseSuccess(data?.data?.projectCount));
	} catch (error) {
		dispatch(getSurveyResponseFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const getCategoryViseProjects = createAsyncThunk(
	'dashboard/categoryViseProjects',
	async (params, { dispatch }) => {
		dispatch(getCategoryViseProjectsBegin());
		const url = '/admin/project/category';

		try {
			const { data } = await http({ url, token: true });
			dispatch(getCategoryViseProjectsSuccess(data?.data?.projectCategoryCount));
		} catch (error) {
			dispatch(getCategoryViseProjectsFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export default dashboardSlice.reducer;
