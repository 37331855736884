import { authRoles } from 'app/auth';
import React from 'react';

const ResetPasswordConfig = {
	settings: {
		layout: {
			config: { navbar: false, header: false, toolbar: false, footer: false }
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/reset-password/:resetToken',
			component: React.lazy(() => import('./ResetPassword'))
		}
	]
};

export default ResetPasswordConfig;
