import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import documents from 'app/main/documents/documentsSlice';
import earlySignup from 'app/main/EarlySignup/EarlySignupSlice';
import enquiryUser from 'app/main/Enquiry/EnquirySlice';
import registeredUser from 'app/main/RegisteredUsers/RegisteredUserSlice';
import users from 'app/main/users/usersSlice';
import dashboard from 'app/main/dashboard/dashboardSlice';
import categories from 'app/main/Categories/categoriesSlice';
import common from './commonSlice';
import fuse from './fuse';
import i18n from './i18nSlice';

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		fuse,
		i18n,
		documents,
		earlySignup,
		enquiryUser,
		registeredUser,
		users,
		dashboard,
		common,
		categories,
		...asyncReducers
	});

export default createReducer;
