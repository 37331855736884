import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import register from './registerSlice';
import user from './userSlice';
import password from './passwordSlice';

const authReducers = combineReducers({
	user,
	login,
	register,
	password
});

export default authReducers;
