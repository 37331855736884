import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		type: 'item',
		icon: 'home',
		url: '/dashboard',
		exact: true
	},
	{
		id: 'users',
		title: 'Users',
		type: 'item',
		icon: 'group',
		url: '/users',
		exact: true
	},
	{
		id: 'categories',
		title: 'Categories',
		type: 'item',
		icon: 'category',
		url: '/categories',
		exact: true
	},
	{
		id: 'manage-faqs',
		title: 'Manage FAQs',
		type: 'item',
		icon: 'help_outline',
		url: '/manage-faqs',
		exact: true
	},
	{
		id: 'email-cms',
		title: 'Email CMS',
		type: 'item',
		icon: 'inbox',
		url: '/email-cms',
		exact: true
	},
	{
		id: 'activity-log',
		title: 'Activity Log',
		type: 'item',
		icon: 'edit',
		url: '/activity-log',
		exact: true
	}
];

export default navigationConfig;
