export const downloadExcelFile = (myBlob, name) => {
	const blob = new Blob([myBlob], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	});
	const blobUrl = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = blobUrl;
	link.download = name;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const downloadFile = url => {
	const link = document.createElement('a');
	link.href = url;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
